import { FileTypes } from '~/types/file';

export const routes = {
  model: {
    getModels: () => '/models',
    getModelById: (uuid: string) => `/models/${uuid}/retrieve`,
    getModelWithNamedValues: (uuid: string) => `/models/${uuid}/retrieve`,
    createModel: () => '/models',
    updateModel: (uuid: string) => `/models/${uuid}`,
    updateModelWithNamedValues: (uuid: string) => `/models/${uuid}`,
    deleteModel: (uuid: string) => `/models/${uuid}`,
    duplicateModel: (uuid: string) => `/models/${uuid}/duplicate`,
    submitModel: (uuid: string) => `/models/${uuid}/submit`,
    approveModel: (uuid: string) => `/models/${uuid}/approve`,
    downloadFile: (uuid: string) => `/models/${uuid}/download`,
    uploadFile: (uuid: string) => `/models/${uuid}/upload`,
  },
  project: {
    getProjects: () => '/projects',
    getProjectById: (uuid: string) => `/projects/${uuid}`,
    getProjectModelsByProjectId: (uuid: string) => `/projects/${uuid}`,
    createProject: () => '/projects',
    updateProject: (uuid: string) => `/projects/${uuid}`,
    updateProjectStatus: (uuid: string, status: string) =>
      `/project-details/${uuid}/status/${status}`,
    deleteProject: (uuid: string) => `/projects/${uuid}`,
    getProjectsBySalesforceOpportunityId: (sf_opportunity_id: string) =>
      `/projects/salesforce-opportunity/${sf_opportunity_id}`,
    upsertFilter: (projectId: string) =>
      `projects/solar/${projectId}/filters/save`,
  },
  userProject: {
    getProjects: (params?: string) => `/projects${params && `?${params}`}`,
    getProjectById: (uuid: string) => `/user/projects/${uuid}`,
    // TODO: rename when we are sure we use the other one. Avoiding to break a lot of things
    getProjectByUUID: (uuid: string) => `/projects/${uuid}`,
    getSolarProjectByUUID: (uuid: string) => `/projects/solar/${uuid}`,
    getStorageProjectByUUID: (uuid: string) => `/projects/storage/${uuid}`,
    createSolarProject: () => '/projects/solar',
    createStorageProject: () => '/projects/storage',
    updateSolarProject: (uuid: string) => `/projects/solar/${uuid}`,
    updateStorageProject: (uuid: string) => `/projects/storage/${uuid}`,
    updateProject: (uuid: string) => `/user/projects/${uuid}`,
    deleteProject: (uuid: string) => `/user/projects/${uuid}`,
    downloadFile: (uuid: string) => `/user/projects/${uuid}/download`,
    uploadFile: () => `/user/projects/upload`,
    getProjectScenarioRanking: (uuid: string, rankingsId: string) =>
      `/user/projects/${uuid}/bmot/${rankingsId}/get_rankings_sheet_download_url`,
    saveStorageFilters: (uuid: string) =>
      `/projects/storage/${uuid}/filters/save`,
  },
  search: {
    searchProjectLocation: () => `/search/location`,
    searchProjectLocationByLatLng: () => `/search/lat-lng`,
    getLocationByGooglePlaceId: (placeId: string) =>
      `/search/location/${placeId}`,
  },
  engine: {
    getStorageEngineResults: (uuid: string) => `/engine/storage/${uuid}`,
    getSolarEngineResultsDownloadUrl: (requestId: string) =>
      `/engine/results/${requestId}`,
    runStorageEngine: (uuid: string) => `/engine/storage/${uuid}`,
  },
  auth: {
    auth: () => '/auth',
    login: () => '/users/auth',
    refreshToken: () => '/users/auth/refresh',
    settings: (id: string | number) => `/users/settings/${id}`,
    resendVerificationEmail: () => '/users/settings/resend_verification_email',
  },
  anza: {
    locations: () => '/anza/locations',
    modules: () => '/anza/modules',
    company: {
      updateModule: (uuid: string) => `/company/modules/${uuid}`,
    },
    vendor: {
      modules: () => '/vendor/modules',
      vendorModules: (uuid: string) => `/vendor/modules/${uuid}`,
    },
    project: {
      topRankings: (projectUuid: string, vendorUuid: string) =>
        `/metrics/projects/${projectUuid}/vendors/${vendorUuid}`,
    },
    metrics: {
      pipeline: () => '/metrics/pipeline',
      vendorModules: (vendorUuid: string, moduleUuid?: string) =>
        `/metrics/vendors/${vendorUuid}${
          moduleUuid ? `?moduleId=${moduleUuid}` : ''
        }`,
      vendorProjects: (vendorUuid: string) =>
        `/metrics/vendors/${vendorUuid}/active-projects`,
      allVendorsModules: () => '/metrics/vendors/all',
      allVendorsProjects: () => '/metrics/vendors/all/active-projects',
    },
    supplierDashboard: {
      pipeline: () => '/supplier-dashboard/pipeline',
    },
    module: {
      getModule: (uuid: string) => `/module/${uuid}`,
      downloadFile: (
        moduleUuid: string,
        fileUuid: string,
        fileType?: FileTypes
      ) => `/module/${moduleUuid}/file/${fileUuid}?file_type=${fileType}`,
    },
  },
  admin: {
    companies: (params?: string | void) =>
      `/admin/companies${params ? `?${params}` : ''}`,
    companiesSettings: (id: string) => `/admin/companies/${id}`,
    adminCreateCompany: () => '/admin/companies',
    adminUpdateCompany: (id: number) => `/admin/companies/${id}`,
    domains: () => '/admin/domains',
    domainsSettings: (id: string) => `/admin/domains/${id}`,
    dcBlocks: () => '/admin/dc-blocks',
    dcBlockQuote: () => '/admin/dc-blocks/quote',
    locationModifiers: () => '/admin/location-modifiers',
    locationModifiersSettings: (id: string | number) =>
      `/admin/location-modifiers/${id}`,
    modules: () => '/admin/modules',
    modulesSettings: (id: string) => `/admin/modules/${id}`,
    triggerEngineRuns: () => '/engine/trigger',
    modulesConnectorTypes: () => '/admin/modules/connector-types',
    users: () => '/admin/users',
    user: (id: number) => `/admin/users/${id}`,
    userActivate: (id: string) => `/admin/users/${id}/activate`,
    userDeactivate: (id: string) => `/admin/users/${id}/deactivate`,
    roles: () => '/admin/roles',
    searchFiles: (params?: string) =>
      `/files/search${params ? '?' : ''}${params}`,
    getUploadUrl: () => '/files/get_upload_url',
    racks: () => '/admin/racks',
    inverters: () => '/inverters',
    anzaEngineInputs: (id: string) => `/engine/get-inputs/${id}`,
    anzaStorageEngineInputs: (id: string, engineVersion: string) =>
      `/engine/get-storage-inputs/${id}${
        engineVersion && `?engineVersion=${engineVersion}`
      }`,
    moduleUpdates: (params?: string) =>
      `/admin/modules/logs${params ? '?' : ''}${params}`,
    projects: (params?: string) =>
      params ? `/admin/projects?${params}` : '/admin/projects',
    parsers: {
      parsePanFile: (
        filePath: string,
        fileType: string,
        ignoreLidLoss?: boolean
      ) => {
        return ignoreLidLoss
          ? `/admin/modules/parse-pan-file?filePath=${filePath}&fileType=${fileType}&ignoreLidLoss=true`
          : `/admin/modules/parse-pan-file?filePath=${filePath}&fileType=${fileType}`;
      },
    },
    getProjectSpecificPricing: (id: string) => `projects/${id}/module-pricing`,
    deleteProjectSpecificPricing: (projectId: string, uuid: string) =>
      `projects/${projectId}/module-pricing/${uuid}`,
    addProjectSpecificPricing: (id: string) => `projects/${id}/module-pricing`,
    editProjectSpecificPricing: (projectId: string, uuid: string) =>
      `projects/${projectId}/module-pricing/${uuid}`,
    moduleUpdateLogs: () => '/admin/audit-logs/module-update-logs',
    unapprovedStorageProject: (id: string) =>
      `/admin/projects/${id}/unapproved`,
    approvedStorageProject: (id: string) => `/admin/projects/${id}/approved`,
  },
  modules: {
    updateModulePricing: (moduleUuid: string) =>
      `/vendor/modules/${moduleUuid}/pricing`,
    confirmModulePricing: (moduleUuid: string) =>
      `/vendor/modules/${moduleUuid}/confirm-pricing`,
  },
  dcblock: {
    getDcBlockByUuid: (uuid: string) => `/admin/dc-blocks/${uuid}`,
  },
  config: {
    config: () => '/config',
  },
  dashboards: {
    getDashboardById: (id: string) => `/dashboards/${id}`,
  },
  productOfferings: {
    getProductOfferings: () => '/product-offerings',
  },
};
