import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { VendorPipelineResponse } from '~/services/api/anza';
import { getSecondsInHours } from '~/utils/timeConversions';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';

export const supplierDashboardApi = createApi({
  reducerPath: 'vendorDashboardApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: getSecondsInHours(12),
  tagTypes: ['Pipeline'],
  endpoints: (builder) => ({
    // 👇 Query: Get Vendor's Pipeline Data
    getDashboardPipeline: builder.query<VendorPipelineResponse, void>({
      query: () => routes.anza.supplierDashboard.pipeline(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [{ type: 'Pipeline', id: 'PIPELINE' }],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
  }),
});

export const { useGetDashboardPipelineQuery } = supplierDashboardApi;
